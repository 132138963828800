import Types from '../types/payroll';

const initialState = {
  payrolls: {
    loading: false,
    data: [],
    error: '',
    meta: {},
    total_hours: {},
  },
  payrollSubmissions: {
    loading: false,
    data: [],
    error: '',
  },
  payrollSubmitted: {
    loading: false,
    data: {},
    error: '',
  },
  payrollEntiresFilter: {
    loading: false,
    data: [],
    meta: {},
    error: '',
  },
  approveDeletePayroll: {
    loading: false,
    data: {},
    error: '',
  },
  markPendingPayroll: {
    loading: false,
    data: {},
    error: '',
  },
  deleteNAEntry: {
    loading: false,
    data: {},
    error: '',
  },
  updateNAEntry: {
    loading: false,
    data: {},
    error: '',
  },
  exportPayroll: {
    loading: false,
    data: {},
    error: '',
  },
  submitPayroll: {
    loading: false,
    data: {},
    error: '',
  },
  exportPayrollSummaries: {
    loading: false,
    data: {},
    error: '',
  },
};

export default function Payroll(state = initialState, action) {
  switch (action.type) {
    case Types.GET_PAYROLLS_REQUEST:
      return {
        ...state,
        payrolls: {
          ...state.payrolls,
          loading: true,
        },
      };
    case Types.GET_PAYROLLS_SUCCESS:
      return {
        ...state,
        payrolls: {
          error: '',
          loading: false,
          data: action.payload,
          meta: action.meta,
          total_hours: action.total_hours,
        },
      };
    case Types.GET_PAYROLLS_ERROR:
      return {
        ...state,
        payrolls: {
          loading: false,
          error: action.payload,
          data: [],
          meta: {},
          total_hours: {},
        },
      };
    case Types.GET_PAYROLL_SUBMISSIONS_REQUEST:
      return {
        ...state,
        payrollSubmissions: {
          ...state.payrollSubmissions,
          loading: true,
        },
      };
    case Types.GET_PAYROLL_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        payrollSubmissions: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.GET_PAYROLL_SUBMISSIONS_ERROR:
      return {
        ...state,
        payrollSubmissions: {
          loading: false,
          error: action.payload,
          data: [],
        },
      };
    case Types.GET_PAYROLL_SUBMITTED_REQUEST:
      return {
        ...state,
        payrollSubmitted: {
          ...state.payrollSubmitted,
          loading: true,
        },
      };
    case Types.GET_PAYROLL_SUBMITTED_SUCCESS:
      return {
        ...state,
        payrollSubmitted: {
          error: '',
          loading: false,
          data: action.isMore
            ? {
                ...state.payrollSubmitted.data,
                data: [
                  ...state.payrollSubmitted.data.data,
                  ...action.payload.data,
                ],
              }
            : action.payload,
        },
      };
    case Types.GET_PAYROLL_SUBMITTED_ERROR:
      return {
        ...state,
        payrollSubmitted: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.GET_PAYROLL_ENTRIES_FILTER_REQUEST:
      return {
        ...state,
        payrollEntiresFilter: {
          ...state.payrollEntiresFilter,
          loading: true,
        },
      };
    case Types.GET_PAYROLL_ENTRIES_FILTER_SUCCESS:
      return {
        ...state,
        payrollEntiresFilter: {
          error: '',
          loading: false,
          data: action.payload?.isMore
            ? [...state.payrollEntiresFilter.data, ...action.payload.data]
            : action.payload?.data || [],
          meta: action.meta,
        },
      };
    case Types.GET_PAYROLL_ENTRIES_FILTER_ERROR:
      return {
        ...state,
        payrollEntiresFilter: {
          loading: false,
          error: action.payload,
          data: [],
          meta: {},
        },
      };
    case Types.APPROVE_DELETE_PAYROLL_REQUEST:
      return {
        ...state,
        approveDeletePayroll: {
          ...state.approveDeletePayroll,
          loading: true,
        },
      };
    case Types.APPROVE_DELETE_PAYROLL_SUCCESS:
      return {
        ...state,
        approveDeletePayroll: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.APPROVE_DELETE_PAYROLL_ERROR:
      return {
        ...state,
        approveDeletePayroll: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.MARK_PENDING_PAYROLL_REQUEST:
      return {
        ...state,
        markPendingPayroll: {
          ...state.markPendingPayroll,
          loading: true,
        },
      };
    case Types.MARK_PENDING_PAYROLL_SUCCESS:
      return {
        ...state,
        markPendingPayroll: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.MARK_PENDING_PAYROLL_ERROR:
      return {
        ...state,
        markPendingPayroll: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.DELETE_NA_ENTRY_REQUEST:
      return {
        ...state,
        deleteNAEntry: {
          ...state.deleteNAEntry,
          loading: true,
        },
      };
    case Types.DELETE_NA_ENTRY_SUCCESS:
      return {
        ...state,
        deleteNAEntry: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.DELETE_NA_ENTRY_ERROR:
      return {
        ...state,
        deleteNAEntry: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.UPDATE_NA_ENTRY_REQUEST:
      return {
        ...state,
        updateNAEntry: {
          ...state.updateNAEntry,
          loading: true,
        },
      };
    case Types.UPDATE_NA_ENTRY_SUCCESS:
      return {
        ...state,
        updateNAEntry: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.UPDATE_NA_ENTRY_ERROR:
      return {
        ...state,
        updateNAEntry: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.EXPORT_PAYROLL_REQUEST:
      return {
        ...state,
        exportPayroll: {
          ...state.exportPayroll,
          loading: true,
        },
      };
    case Types.EXPORT_PAYROLL_SUCCESS:
      return {
        ...state,
        exportPayroll: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.EXPORT_PAYROLL_ERROR:
      return {
        ...state,
        exportPayroll: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.SUBMIT_PAYROLL_REQUEST:
      return {
        ...state,
        submitPayroll: {
          ...state.submitPayroll,
          loading: true,
        },
      };
    case Types.SUBMIT_PAYROLL_SUCCESS:
      return {
        ...state,
        submitPayroll: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.SUBMIT_PAYROLL_ERROR:
      return {
        ...state,
        submitPayroll: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    case Types.EXPORT_PAYROLL_SUMMARIES_REQUEST:
      return {
        ...state,
        exportPayrollSummaries: {
          ...state.exportPayrollSummaries,
          loading: true,
        },
      };
    case Types.EXPORT_PAYROLL_SUMMARIES_SUCCESS:
      return {
        ...state,
        exportPayrollSummaries: {
          error: '',
          loading: false,
          data: action.payload,
        },
      };
    case Types.EXPORT_PAYROLL_SUMMARIES_ERROR:
      return {
        ...state,
        exportPayrollSummaries: {
          loading: false,
          error: action.payload,
          data: {},
        },
      };
    default:
      return state;
  }
}
