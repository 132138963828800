import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_PAYROLLS_REQUEST',
  'GET_PAYROLLS_SUCCESS',
  'GET_PAYROLLS_ERROR',
  'GET_PAYROLL_SUBMISSIONS_REQUEST',
  'GET_PAYROLL_SUBMISSIONS_SUCCESS',
  'GET_PAYROLL_SUBMISSIONS_ERROR',
  'GET_PAYROLL_SUBMITTED_REQUEST',
  'GET_PAYROLL_SUBMITTED_SUCCESS',
  'GET_PAYROLL_SUBMITTED_ERROR',
  'GET_PAYROLL_ENTRIES_FILTER_REQUEST',
  'GET_PAYROLL_ENTRIES_FILTER_SUCCESS',
  'GET_PAYROLL_ENTRIES_FILTER_ERROR',
  'APPROVE_DELETE_PAYROLL_REQUEST',
  'APPROVE_DELETE_PAYROLL_SUCCESS',
  'APPROVE_DELETE_PAYROLL_ERROR',
  'MARK_PENDING_PAYROLL_REQUEST',
  'MARK_PENDING_PAYROLL_SUCCESS',
  'MARK_PENDING_PAYROLL_ERROR',
  'DELETE_NA_ENTRY_REQUEST',
  'DELETE_NA_ENTRY_SUCCESS',
  'DELETE_NA_ENTRY_ERROR',
  'UPDATE_NA_ENTRY_REQUEST',
  'UPDATE_NA_ENTRY_SUCCESS',
  'UPDATE_NA_ENTRY_ERROR',
  'EXPORT_PAYROLL_REQUEST',
  'EXPORT_PAYROLL_SUCCESS',
  'EXPORT_PAYROLL_ERROR',
  'SUBMIT_PAYROLL_REQUEST',
  'SUBMIT_PAYROLL_SUCCESS',
  'SUBMIT_PAYROLL_ERROR',
  'EXPORT_PAYROLL_SUMMARIES_REQUEST',
  'EXPORT_PAYROLL_SUMMARIES_SUCCESS',
  'EXPORT_PAYROLL_SUMMARIES_ERROR',
  'EXPORT_PAYROLL_SUBMITTED_REQUEST',
  'EXPORT_PAYROLL_SUBMITTED_SUCCESS',
  'EXPORT_PAYROLL_SUBMITTED_ERROR'
);
