import React from 'react';

import { Route, Redirect } from 'react-router-dom';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

const AuthenticateRoute = props => {
  const { component: Component, ...rest } = props;
  const auth = useSelector(({ auth }) => auth);

  const jwt_token = get(auth, 'user.auth_token', null);
  const hasAcceptedTnC = get(auth, 'user.has_accepted_tc', false);

  return (
    <Route
      {...rest}
      render={props =>
        jwt_token && hasAcceptedTnC ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/sign-in', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default AuthenticateRoute;
