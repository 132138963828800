import Types from 'store/types/auth';

const initialState = {
  loading: false,
  isError: false,
  message: '',
  status: null,
  isLoggedIn: false,
  isActiveSession: null,
  user: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case Types.LOGIN_INPROGRESS:
      return {
        ...state,
        loading: true,
        isError: false,
        message: '',
        status: null,
      };

    case Types.LOGIN_SUCCESS: {
      const expiresAt = Date.now();
      localStorage.setItem('expiresAt', expiresAt);
      return {
        ...state,
        loading: false,
        isError: false,
        status: action.status,
        isActiveSession: true,
        isLoggedIn: true,
        user: action.user,
      };
    }

    case Types.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        isError: true,
        message: action.message,
        status: action.status,
      };
    case Types.LOGIN_DEACTIVATED:
      return {
        ...state,
        user: action.user,
      };
    case Types.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isActiveSession: null,
        message: action.message,
        isLoggedIn: false,
        user: null,
      };
    case Types.GET_PROFILE_INPROGRESS:
      return {
        ...state,
        loading: true,
        isError: false,
        status: 0,
      };

    case Types.ACCEPT_TNC_SUCCESS:
    case Types.GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isActiveSession: true,
        isError: false,
        status: 1,
        user: {
          ...state.user,
          ...action.user,
        },
      };
    case Types.SYNC_UPDATE_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
      };
    case Types.UPDATE_TOKEN: {
      const lastCallRefresh = Date.now();
      localStorage.setItem('expiresAt', lastCallRefresh);
      return {
        ...state,
        user: {
          ...state.user,
          auth_token: action.data,
        },
        lastCallRefresh,
      };
    }
    case Types.GET_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        isError: true,
        status: 0,
      };
    default:
      return state;
  }
}
