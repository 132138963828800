import React from 'react';

import { Route, Redirect } from 'react-router-dom';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import TermsAndConditions from '../../views/pages/TermsAndConditions';

const PublicRoute = props => {
  const { component: Component, publicView, ...rest } = props;
  const auth = useSelector(({ auth }) => auth);

  const jwt_token = get(auth, 'user.auth_token', null);
  const hasAcceptedTnC = get(auth, 'user.has_accepted_tc', false);

  return (
    <Route
      {...rest}
      render={props =>
        !jwt_token || publicView ? (
          <Component {...props} />
        ) : !hasAcceptedTnC ? (
          <TermsAndConditions />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PublicRoute;
