import axios from 'axios';
// import get from 'lodash/get';
import Request from './request';
// import { store } from '../store';

export const getApprovalsList = (
  q = '',
  ownerId = '',
  program,
  page = 1,
  moreFilters = {}
) => {
  return Request.call({
    url: '/csrs/approvals/list',
    method: 'GET',
    params: {
      q,
      ownerId,
      program_id: program ? program : '',
      page,
      ...moreFilters,
      limit: 100,
    },
  });
};

export const getPendingAuthList = (q, ownerId = '', program, page = 1) => {
  return Request.call({
    url: '/csrs/missing-auth/list',
    method: 'GET',
    params: {
      page,
      q,
      program_id: program ? program : '',
      ...(ownerId ? { ownerId } : {}),
      limit: 100,
    },
  });
};

export const getServicesListForCSR = csr => {
  return Request.call({
    url: `/csrs/${csr}/services`,
    method: 'GET',
  });
};

export const addManualCSR = (csr, data) => {
  return Request.call({
    url: `/csrs/${csr}`,
    method: 'POST',
    data,
  });
};

export const getSubmissionsList = (
  q = '',
  ownerId = '',
  program = 0,
  page = 1,
  moreFilters = {}
) => {
  return Request.call({
    url: '/csrs/submissions/list',
    method: 'GET',
    params: {
      q,
      ownerId,
      program_id: program ? program : '',
      page,
      ...moreFilters,
      limit: 100,
    },
  });
};

export const submitTimeEntries = async (csr, data) => {
  return Request.call({
    url: `/csrs/${csr}/approve`,
    method: 'POST',
    data,
  });
};

export const approveMultipleTimeEntries = async data => {
  return Request.call({
    url: `/csrs/approve/many`,
    method: 'POST',
    data,
  });
};

export const submitMultipleTimeEntries = async data => {
  return Request.call({
    url: `/csrs/submit/many`,
    method: 'POST',
    data,
  });
};

export const viewCsr = (participant, csr) => {
  return Request.call({
    url: `/csrs/${csr}`,
    method: 'GET',
  });
};

export const approveCsr = (participant, csr) => {
  return Request.call({
    url: `/csrs/${csr}/final-approve`,
    method: 'POST',
  });
};

export const submitCsr = (participant, csr) => {
  return Request.call({
    url: `/csrs/${csr}/submit`,
    method: 'POST',
  });
};

export const getSubmittedCsrs = (participant, params = {}) => {
  return Request.call({
    url: `/participants/${participant}/csrs`,
    method: 'GET',
    params,
  });
};

export const getMultipleCSRsForPrint = ids => {
  return Request.call({
    url: `/csrs/print/many`,
    method: 'POST',
    data: ids,
  });
};

export const getCSRForPrint = id => {
  return Request.call({
    url: `/csrs/${id}/print`,
    method: 'GET',
  });
};

export const download = async id => {
  // const storeData = store.getState();
  // const token = get(storeData, 'auth.user.auth_token', null);
  const token = localStorage.getItem('token') || null;

  // Using axios here, as using Request.call allows to download the file but content is broken/corrupted
  // If we use Request.call content of file gets undefined in text file, images are broken
  return axios({
    url: `${process.env.REACT_APP_API_URL}/csrs/${id}/download`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const downloadX12 = async id => {
  // const storeData = store.getState();
  // const token = get(storeData, 'auth.user.auth_token', null);
  const token = localStorage.getItem('token') || null;

  // Using axios here, as using Request.call allows to download the file but content is broken/corrupted
  // If we use Request.call content of file gets undefined in text file, images are broken
  return axios({
    url: `${process.env.REACT_APP_API_URL}/csrs/${id}/download-x12`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const downloadCSRZip = async data => {
  // const storeData = store.getState();
  // const token = get(storeData, 'auth.user.auth_token', null);
  const token = localStorage.getItem('token') || null;

  // Using axios here, as using Request.call allows to download the file but content is broken/corrupted
  // If we use Request.call content of file gets undefined in text file, images are broken
  return axios({
    url: `${process.env.REACT_APP_API_URL}/csrs/download/many`,
    method: 'POST',
    data,
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const downloadX12CSRZip = async data => {
  // const storeData = store.getState();
  // const token = get(storeData, 'auth.user.auth_token', null);
  const token = localStorage.getItem('token') || null;

  // Using axios here, as using Request.call allows to download the file but content is broken/corrupted
  // If we use Request.call content of file gets undefined in text file, images are broken
  return axios({
    url: `${process.env.REACT_APP_API_URL}/csrs/download-x12/many`,
    method: 'POST',
    data,
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const removeManualEntry = (csr, service) => {
  return Request.call({
    url: `/csrs/${csr}/${service}`,
    method: 'DELETE',
  });
};

export const editManualCSR = (csr, entryId, data) => {
  return Request.call({
    url: `/csrs/${csr}/${entryId}`,
    method: 'PUT',
    data,
  });
};

export const apiGenerateReport = data => {
  return Request.call({
    url: `csrs/submit/report`,
    method: 'POST',
    data,
  });
};
export const apiGenerateCsrs = id => {
  return Request.call({
    url: `csrs/${id}/regenerate`,
    method: 'POST',
    data: {},
  });
};
export const apiDownloadReport = data => {
  // const storeData = store.getState();
  // const token = get(storeData, 'auth.user.auth_token', null);
  const token = localStorage.getItem('token') || null;

  // Using axios here, as using Request.call allows to download the file but content is broken/corrupted
  // If we use Request.call content of file gets undefined in text file, images are broken
  return axios({
    url: `${process.env.REACT_APP_API_URL}/csrs/submit/report/download`,
    method: 'POST',
    data,
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const apiPrintReport = data => {
  return Request.call({
    url: `csrs/submit/report/print`,
    method: 'POST',
    data,
  });
};
